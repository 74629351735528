html,
body,
div,
span,
applet,
object,
iframe,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend {
  vertical-align: baseline;
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  outline: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

html {
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ol,
ul {
  list-style: none;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  -moz-box-sizing: border-box;
  /*Firefox 1-3*/
  -webkit-box-sizing: border-box;
  /*Safari */
  box-sizing: border-box;
}

a,
a:visited,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}
