:root {
  --clr-white: #fff;

  --clr-primary: #2f49d1;
  --clr-primary-hover: #132aa5;
  --clr-text: #3e466d;
  --clr-text-60: #7c8199;
  --clr-dark-text: #001a4b;
  --clr-default-no-button: #ebedfb;

  --clr-grey: #9ab2e1;
  --clr-grey-border: #dadded;
  --clr-light-grey-bg: #fafbff;

  --clr-blue-primary-button: #586dda;


  --transition-default: all 0.2s ease-out;
}
