*,
::after,
::before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:root {
  -moz-tab-size: 4;
  tab-size: 4;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}
body {
  font-family: 'Proxima Nova', 'Arial', sans-serif;
  font-size: 1rem;
  color: var(--clr-text);
  background: var(--clr-white);
  -webkit-font-smoothing: antialiased;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    content: '\e903';
    font-family: 'icon-font-outsail' !important;
    color: transparent;
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
p {
  line-height: 1.4;
}

main {
  // Browsers which fully support CSS variables (iOS 11.0)
  padding-top: constant(safe-area-inset-top);
  // Browsers which fully support CSS variables (iOS 11+)
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--clr-primary) !important;
}
